const tournamentPaths = {
  1: '/wm-2022',
  2: '/klub-wm',
  5: '/frauen-wm-2023',
  9: '/internationale-testspiele',

  10: '/champions-league',
  11: '/europa-league',
  12: '/conference-league',
  15: '/em-2024',
  16: '/nations-league',
  50: '/frauen-champions-league',
  55: '/frauen-em-2022',

  100: '/dfb-pokal',
  101: '/bundesliga',
  102: '/2-bundesliga',
  103: '/3-liga',
  150: '/frauen-dfb-pokal',
  151: '/frauen-bundesliga',

  200: '/fa-cup',
  201: '/premier-league',
  202: '/championship',
  210: '/efl-cup',

  401: '/laliga',

  501: '/serie-a',

  601: '/ligue-1',
};

export default tournamentPaths;
