import React from 'react';
import {Link} from 'react-router-dom';
import Match from './../match/Match.js';
import tournamentPaths from './../../helper/tournamentPaths.js';
import tableStyles from './../../styles/tableStyles.js';

//          cup            important       local                      other                        foreign
const de = [1, 2, 15, 5,   101, 100, 10,   102, 103, 151, 150,        11, 12, 16, 9, 50, 55,       201, 202, 203, 200, 210, 401, 501, 601];
const region = de;

const MatchesByTournament = ({matches, strings}) => {
  console.log('MatchesByTournament.render()');

  /* Order tournaments by "region" instead of server defaults */
  const tmp = {};                                                   // 1. Split matches into tournaments {101: [...], 55: [...]}
  for (const match of matches) {
    tmp[match.tournamentId] = tmp[match.tournamentId] || [];
    tmp[match.tournamentId].push(match);
  }
  matches = region.flatMap((tournamentId) => {                      // 2. Order matches based on region [{}, {}, ...]
    return tmp[tournamentId];
  });
  matches = matches.filter(m => m !== undefined);                   // 3. Remove undefined entries

  return matches.map((match, index) => {
    const previousMatch = matches[index-1] || {};
    const nextMatch = matches[index+1] || {};
    const tournamentDidChange = match.tournamentId !== previousMatch.tournamentId;
    const tournamentWillChange = match.tournamentId !== nextMatch.tournamentId;
    return (
      <React.Fragment key={match.matchId}>
        {tournamentDidChange &&
          <Link style={{...tableStyles.headerView, ...styles.tournamentHeaderView}} to={tournamentPaths[match.tournamentId]}>
            <div style={tableStyles.headerText}>
              {strings.medium['tournament'+match.tournamentId]}
            </div>
          </Link>
        }
        <Match match={match} strings={strings} first={tournamentDidChange} last={tournamentWillChange}/>
        {!tournamentWillChange && <div style={tableStyles.lineViewContainer}><div style={tableStyles.lineView}/></div>}
        {tournamentWillChange && (index !== matches.length-1) && <div style={tableStyles.footerViewEmpty}/>}
      </React.Fragment>
    );
  });
};

const styles = {
  tournamentHeaderView: {
    alignSelf: 'flex-start',                                        // Force smaller touch width
  },
}

export default MatchesByTournament;
