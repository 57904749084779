import React from 'react';
import {Link} from 'react-router-dom';
import Title from './../Title.js';
import Icon from './../Icon.js';
import withErrorBoundary from './../common/withErrorBoundary.js';
import tournamentPaths from './../../helper/tournamentPaths.js';
import tableStyles from './../../styles/tableStyles.js';
import colors from './../../colors.js';

const More = (props) => {
  console.log('More.render()');
  const {strings} = props;

  /* Meta */
  document.title = 'Alle Fußball Ligen';
  document.head.children.description.content = 'Deutschland: 1. Bundesliga, 2. Bundesliga, 3. Liga, DFB Pokal, Frauen Bundesliga, ... Europa: Champions League, EM, ..., WM 2022, Premier League';

  return (
    <div id="more" className="safe-area-desktop-small">
      <Title>Alle Ligen</Title>
      <div style={{...tableStyles.headerView, ...tableStyles.headerText}}>{strings.medium.teams[1000001]}  🇩🇪</div>
      <Link to={tournamentPaths[101]} style={{...tableStyles.rowViewFirst, ...tableStyles.rowText}}>{strings.medium.tournament101}</Link>
      <Link to={tournamentPaths[102]} style={{...tableStyles.rowView, ...tableStyles.rowText}}>{strings.medium.tournament102}</Link>
      <Link to={tournamentPaths[103]} style={{...tableStyles.rowView, ...tableStyles.rowText}}>{strings.medium.tournament103}</Link>
      <Link to={tournamentPaths[100]} style={{...tableStyles.rowView, ...tableStyles.rowText}}>{strings.medium.tournament100}</Link>
      <Link to={tournamentPaths[151]} style={{...tableStyles.rowView, ...tableStyles.rowText}}>{strings.medium.tournament151}<Icon androidName="gender-female" size={22} color={colors.textTertiary}/></Link>
      <Link to={tournamentPaths[150]} style={{...tableStyles.rowViewLast, ...tableStyles.rowText}}>{strings.medium.tournament150}<Icon androidName="gender-female" size={22} color={colors.textTertiary}/></Link>
      <div style={tableStyles.footerViewEmpty}/>

      <div style={{...tableStyles.headerView, ...tableStyles.headerText}}>{strings.medium.europe}  🇪🇺</div>
      <Link to={tournamentPaths[10]} style={{...tableStyles.rowViewFirst, ...tableStyles.rowText}}>{strings.medium.tournament10}</Link>
      <Link to={tournamentPaths[11]} style={{...tableStyles.rowView, ...tableStyles.rowText}}>{strings.medium.tournament11}</Link>
      <Link to={tournamentPaths[12]} style={{...tableStyles.rowView, ...tableStyles.rowText}}>{strings.medium.tournament12}</Link>
      <Link to={tournamentPaths[15]} style={{...tableStyles.rowView, ...tableStyles.rowText}}>{strings.small.tournament15}</Link>
      <Link to={tournamentPaths[16]} style={{...tableStyles.rowView, ...tableStyles.rowText}}>{strings.medium.tournament16}</Link>
      <Link to={tournamentPaths[50]} style={{...tableStyles.rowView, ...tableStyles.rowText}}>{strings.medium.tournament50}<Icon androidName="gender-female" size={22} color={colors.textTertiary}/></Link>
      <Link to={tournamentPaths[55]} style={{...tableStyles.rowViewLast, ...tableStyles.rowText}}>{strings.medium.tournament55}<Icon androidName="gender-female" size={22} color={colors.textTertiary}/></Link>
      <div style={tableStyles.footerViewEmpty}/>

      <div style={{...tableStyles.headerView, ...tableStyles.headerText}}>{strings.medium.world}  🌍</div>
      <Link to={tournamentPaths[1]} style={{...tableStyles.rowViewFirst, ...tableStyles.rowText}}>{strings.small.tournament1}</Link>
      <Link to={tournamentPaths[2]} style={{...tableStyles.rowView, ...tableStyles.rowText}}>{strings.medium.tournament2}</Link>
      <Link to={tournamentPaths[9]} style={{...tableStyles.rowView, ...tableStyles.rowText}}>{strings.medium.tournament9}</Link>
      <Link to={tournamentPaths[5]} style={{...tableStyles.rowViewLast, ...tableStyles.rowText}}>{strings.medium.tournament5}</Link>
      <div style={tableStyles.footerViewEmpty}/>

      <div style={{...tableStyles.headerView, ...tableStyles.headerText}}>{strings.medium.teams[1000010]}  🏴󠁧󠁢󠁥󠁮󠁧󠁿</div>
      <Link to={tournamentPaths[201]} style={{...tableStyles.rowViewFirst, ...tableStyles.rowText}}>{strings.medium.tournament201}</Link>
      <Link to={tournamentPaths[202]} style={{...tableStyles.rowView, ...tableStyles.rowText}}>{strings.medium.tournament202}</Link>
      <Link to={tournamentPaths[200]} style={{...tableStyles.rowView, ...tableStyles.rowText}}>{strings.medium.tournament200}</Link>
      <Link to={tournamentPaths[210]} style={{...tableStyles.rowViewLast, ...tableStyles.rowText}}>{strings.medium.tournament210}</Link>
      <div style={tableStyles.footerViewEmpty}/>

      <div style={{...tableStyles.headerView, ...tableStyles.headerText}}>{strings.medium.teams[1000002]}  🇪🇸</div>
      <Link to={tournamentPaths[401]} style={{...tableStyles.rowViewFirstLast, ...tableStyles.rowText}}>{strings.medium.tournament401}</Link>
      <div style={tableStyles.footerViewEmpty}/>

      <div style={{...tableStyles.headerView, ...tableStyles.headerText}}>{strings.medium.teams[1000004]}  🇮🇹</div>
      <Link to={tournamentPaths[501]} style={{...tableStyles.rowViewFirstLast, ...tableStyles.rowText}}>{strings.medium.tournament501}</Link>
      <div style={tableStyles.footerViewEmpty}/>

      <div style={{...tableStyles.headerView, ...tableStyles.headerText}}>{strings.medium.teams[1000009]}  🇫🇷</div>
      <Link to={tournamentPaths[601]} style={{...tableStyles.rowViewFirstLast, ...tableStyles.rowText}}>{strings.medium.tournament601}</Link>
      <div style={tableStyles.footerViewEmpty}/>
    </div>
  );
};

export default withErrorBoundary(More, 'More');
