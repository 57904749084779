const tournamentNames = {
  1: 'WM 2022',
  2: 'Klub-WM',
  5: 'Frauen WM 2023',
  9: 'Internationale Testspiele',

  10: 'Champions League',
  11: 'Europa League',
  12: 'Conference League',
  15: 'EM 2024',
  16: 'Nations League',
  50: 'Frauen Champions League',
  55: 'Frauen Em 2022',

  100: 'DFB Pokal',
  101: 'Bundesliga',
  102: '2. Bundesliga',
  103: '3. Liga',
  150: 'Frauen DFB pokal',
  151: 'Frauen Bundesliga',

  200: 'FA Cup',
  201: 'Premier League',
  202: 'Championship',
  210: 'EFL Cup',

  401: 'LaLiga',

  501: 'Serie A',

  601: '/Ligue 1',

};

export default tournamentNames;
